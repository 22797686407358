<template>
  <v-container>
    <div class="d-flex justify-space-between align-center">
      <div>
        <div class="text-h5">
          System Monitor
        </div>
        <v-subheader>Resource to monitor</v-subheader>
      </div>
      <v-btn
        outlined
        rounded
        color="primary"
        @click="() => service = {}"
      >new service</v-btn>
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Name</th>
            <th>URL</th>
            <th class="text-center">Last Check</th>
            <th class="text-center">Status</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in $store.state.service.services"
            :key="item._id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.url }}</td>
            <td class="text-center">{{ item.status?.last_check && (new Date(item.status.last_check)).toLocaleString() }}</td>
            <td class="text-center">
              <v-chip v-if="item.disable === true" outlined small color="grey">DISABLED</v-chip>
              <v-chip v-else-if="!item.status" outlined small color="purple">PENDING</v-chip>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on }">
                  <v-chip
                    outlined
                    small
                    v-on="on"
                    :color="item.status?.status === 'OK' ? 'green' : 'red'"
                  >{{item.status?.status}}</v-chip>
                </template>
                <span>{{ item.status?.message }}</span>
              </v-tooltip>
            </td>
            <td class="text-center">
              <v-btn
                icon
                color="deep-purple accent-4"
                @click="() => service = item"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                color="red accent-4"
                @click="() => $store.dispatch('delete', item._id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon color="blue" :loading="item.status?.loading" @click="checkNow(item)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon color="green accent-4" :to="`/accident?service_id=${item._id}`">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <service-form :value="service" @close="service=null" />
  </v-container>
</template>

<script>
import ServiceForm from '@/components/ServiceForm.vue'
export default {
  components: {
    ServiceForm,
  },
  data () {
    return {
      service: null,
    }
  },
  methods: {
    checkNow(srv) {
      if(srv.status?.loading) return;
      this.$store.commit('update', {...srv, status: {loading: true}})
      this.$axios.get(`service/${srv._id}/check`);
    },
  },
  mounted() {
    // console.log('state', this.$store.state)
  },
}
</script>
