export default {
   state: {
      socket: null,
      services: []
   },
   getters: {
   },
   mutations: {
      set(state, data) {
         for(let i in data) state[i] = data[i];
      },
      add(state, srv) {
         console.log('add-stat', srv);
         state.services.push(srv);
      },
      update(state, srv) {
         // console.log('update-stat', srv);
         state.services = state.services.map(s => {
            if (s._id === srv._id) {
               return srv;
            }
            return s;
         });
      },
   },
   actions: {
      setSocket({state}, soc) {
         state.socket = soc;
      },
      removeService(srv) {
         this.services = this.services.filter(s => s.id !== srv.id);
      },
      editService(srv) {
         this.socket.emit('edit-service', srv);
      },
      newService(srv) {
         this.socket.emit('new-service', srv);
      },
      delService(srv) {
         this.socket.emit('del-service', srv);
      },
   },
   modules: {
   }
}
