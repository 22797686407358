<template>
   <v-dialog v-model="open" max-width="600px">
      <v-card>
         <v-card-title>
            <span class="text-h5">Settings</span>
         </v-card-title>
         <v-card-text>
            <v-row justify="space-between" >
               <v-col cols="12" md="6" >
                  <v-checkbox v-model="settings.telegram" label="Telegram Notification"/>
               </v-col>
               <v-col cols="12" class="text-center">
                  <v-btn @click="save" width="120" color="primary" dark>Save</v-btn>
               </v-col>
            </v-row>
         </v-card-text>
      </v-card>
   </v-dialog>
</template>
<script>
export default {
   props: ['value'],
   data: () => ({
      settings: [],
      match: 'Foobar',
      max: 0,
      model: 'Foobar',
   }),
   watch: {
      value (val) {
         if (!val) return;
         this.$axios.get('/settings')
            .then(res => {
               this.settings = res.data;
            })
            .catch(err => {
               console.log(err);
            })
      },
   },
   computed: {
      open: {
         get () {
            return this.value
         },
         set (val) {
            this.$emit('input', val)
         },
      },
   },
   methods: {
      save () {
         this.$axios.post('/settings', this.settings)
            .then(({data}) => {
               if(data.status !== 1)this.$alert('Ooops!', data.msg, 'error');
               else {
                  this.$alert('Success!', data.msg, 'success');
                  this.$emit('input', false);
               }
            })
            .catch(() => {
               this.$alert('Ooops!', 'Fail to save data', 'error');
            })
      },
   },
}
</script>