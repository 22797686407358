import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
   {path: '/login', component: LoginView},
   {
      path: '/',
      name: 'home',
      component: HomeView
   },
   {
      path: '/accident',
      name: 'accident logs',
      component: () => import(/* webpackChunkName: "accident" */ '../views/AccidentView.vue')
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

export default router
