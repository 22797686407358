<template>
   <v-row justify="center">
      <v-dialog :value="!!value" @click:outside="close" max-width="600px">
         <v-card>
            <v-card-title>
               <span class="text-h5">{{!!fm._id && 'Edit' || 'New'}} Service</span>
            </v-card-title>
            <v-card-text>
               <v-form ref="form">
                  <v-row>
                     <v-col cols="12" sm="6">
                        <v-text-field
                        v-model="fm.name"
                           label="Name*"
                           required
                        ></v-text-field>
                     </v-col>
                     <v-col cols="12" sm="6">
                        <v-select
                           label="Method*"
                           v-model="fm.method"
                           :items="['GET', 'POST', 'PUT']"
                        />
                     </v-col>
                     <v-col cols="12">
                        <v-text-field
                        v-model="fm.url"
                           label="URL*"
                           required
                        ></v-text-field>
                     </v-col>
                     <v-col cols="6">
                        <v-textarea
                           rows="5"
                           label="Headers"
                           v-model="fm.header"
                        />
                     </v-col>
                     <v-col cols="6">
                        <v-textarea
                           rows="5"
                           label="Body"
                           v-model="fm.body"
                        />
                     </v-col>
                     <v-col cols="12" sm="6">
                        <v-text-field v-model="fm.check_interval" type="number" label="Check Interval" placeholder="in milisecond" />
                     </v-col>
                     <v-col cols="12" sm="6">
                        <v-autocomplete
                           v-model="fm.wait_rsl_from" :items="services" label="Wait for" />
                     </v-col>
                     <v-col cols="12" sm="6">
                        <v-checkbox
                           v-model="fm.disable"
                           true-value="true"
                           label="Disabled"
                        />
                     </v-col>
                     <v-col cols="12" sm="6">
                        <v-text-field v-model="fm.result_var" label="Save result as variable" />
                     </v-col>
                  </v-row>
               </v-form>
               <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="purple darken-1" text @click="close">
                  Close
               </v-btn>
               <v-btn :loading="loading" color="blue darken-1" text @click="save">
                  Save
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-row>
</template>
<script>
export default {
   props: ['value'],
   data: () => ({
      dialog: false,
      fm: {},
      loading: false
   }),
   computed: {
      services() {
         return this.$store.state.services?.map((s) => ({value: s._id, text: s.name})) || [];
      }
   },
   watch: {
      value(val) {
         if(!val) return;
         this.fm = {...val};
      }
   },
   methods: {
      save() {
         if(!this.$refs.form.validate()) return;
         const {_id, ...data} = this.fm;
         this.loading = true;
         (_id
            && this.$axios.put('/service/' + _id, data)
            || this.$axios.post('/service', data)
         ).then((res) => {
            this.$emit('close', res.data);
         }).catch((err) => {
            this.$emit('close', err.response.data);
         }).finally(() => {
            this.loading = false;
         });
      },
      close() {
         this.$emit('close');
      }
   }
}
</script>