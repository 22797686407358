import Vue from 'vue'
import Vuex from 'vuex'
import service from './service'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
   state: {
      alert: [],
   },
   getters: {
   },
   mutations: {
      set(state, arr) {
         for(let i in arr) state[i] = arr[i];
      },
      addAlert(state, data) {
         state.alert.push(data);
      },
      delAlert(state, idx) {
         state.alert.splice(idx, 1);
      },
   },
   actions: {
   },
   modules: {
      service,
      auth,
   }
})
