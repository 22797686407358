<template>
   <v-container style="height: 100vh;">
      <v-form style="height: 100vh;" ref="form" @submit.prevent="login" class="row justify-center align-center">
   
         <!-- <div class="title">
            <img src="https://vuematerial.io/assets/logo-color.png">
            <div class="md-title">Vue Material</div>
            <div class="md-body-1">Build beautiful apps with Material Design and Vue.js</div>
         </div> -->
         <v-card width="460" titled>
            <v-card-title>Welcome!</v-card-title>
            <v-card-text class="py-0">please login to continue</v-card-text>
            <v-card-text>
               <v-text-field required label="Username" v-model="fm.username" autofocus/>
               <v-text-field required label="Password" v-model="fm.password" type="password"/>
            </v-card-text>
      
            <v-card-actions class="justify-center">
               <v-btn outlined rounded width="200" color="primary" :loading="loading" type="submit">Log in</v-btn>
            </v-card-actions>
         </v-card>
         <!-- <div class="loading-overlay" v-if="loading">
            <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
         </div> -->
   
      </v-form>
      <!-- <div class="background" /> -->
   </v-container>
</template>

<script>
export default {
   data: () => ({
      change_pwd: false,
      fm: {},
      usrRules: [v => !!v || 'Username harus diisi' ],
      pwdRules: [v => !!v || 'Password harus diisi' ],
      loading: false,
      token: null,
      message: 'silahkan login untuk melanjutkan'
   }),
   methods: {
      login() {
         console.log(this.fm);
         if(!this.$refs.form.validate()) return;
         this.loading = true;
         this.$axios.post('login', this.fm).then(rsl => {
            const {status, msg, ...data} = rsl.data;
            if(status === 1) {
               // this.$alert({ type: 'success', title: msg, showConfirmButton: false, timer: 1500 });
               this.$store.dispatch('auth/ok', data);
            } else {
               this.$alert({title: 'Ooops!', text: msg, type: 'warning'});
               this.fm.password = '';
            }
         },
         () => {
            this.$alert('Ooops!', 'Terjadi kesalahan', 'error');
         }).finally(() => { this.loading = false });
      },
      checkPwd(v) {
         return v === this.fm.password || 'Kedua password tidak sama';
      },
   }

};
</script>
