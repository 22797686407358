<template>
   <v-layout class="overflow-visible" style="height: 56px;">
      <v-bottom-navigation
         v-model="value"
         bg-color="teal"
         mode="shift"
         fixed
      >
         <v-btn v-for="(n,i) in nav" :key="i" :to="n.link">
            <v-icon>mdi-{{n.icon}}</v-icon>
            <span>{{n.text}}</span>
         </v-btn>
         <v-btn @click="viewSetting=true" >
            <v-icon>mdi-cog</v-icon>
            <span>Settings</span>
         </v-btn>
         <v-btn @click="logout" >
            <v-icon>mdi-logout-variant</v-icon>
            <span>Logout</span>
         </v-btn>
         <!-- <v-menu>
            <template v-slot:activator="{ props }">
               <v-btn v-bind="props" >
                  <v-icon>mdi-account</v-icon>
                  <span>User</span>
               </v-btn>
            </template>
            <v-list>
               <v-list-item>
                  <v-list-item-title>Logout</v-list-item-title>
               </v-list-item>
            </v-list>
         </v-menu> -->
      </v-bottom-navigation>
      <SettingsForm v-model="viewSetting" />
   </v-layout>
</template>
<script>
import SettingsForm from './SettingsForm.vue';

export default {
    data: () => ({
        value: 0,
        nav: [
            { icon: "home", text: "Home", link: "/" },
            { icon: "bug", text: "Accident", link: "/accident" },
            // { icon: 'cog', text: 'Settings', link: '/setting' },
        ],
         viewSetting: false,
    }),
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
        }
    },
    components: { SettingsForm }
}
</script>