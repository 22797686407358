<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
    <Nav v-if="$store.state.auth.user._id"/>
    <alert/>
  </v-app>
</template>

<script>
import { io } from "socket.io-client";
import Alert from '@/components/Alert';
import Nav from '@/components/Nav';
import cookies from 'js-cookie'

export default {
  components: { Alert, Nav },
  name: 'App',

  data: () => ({
    redirected_path: null,
  }),
  beforeCreate() {
    const token = cookies.get(process.env.VUE_APP_JWT_HEADER);
    if(token) {
      // this.redirected_path = this.$route.query.redirected_path;
      this.$axios.defaults.headers.common[process.env.VUE_APP_JWT_HEADER] = token;
      this.$axios.get('/user/profile')
        .then(({data}) => {
          if(data.status === 1) this.$store.commit('auth/set', data.data);
          else this.$router.push('/login');
        })
        .catch(() => {
          this.$store.dispatch('logout');
        });
    } else if(this.$route.path !== '/login') this.$router.push('/login');
  },
  watch: {
    '$store.state.auth.user': function (val) {
      if(val?._id) {
        if(this.$route.path === '/login') this.$router.push(this.redirected_path || '/');
      }
      else this.$router.push('/login');
    }
  },
  mounted () {
    const socket = io(process.env.VUE_APP_SOC_URL);

    socket.on("connect", () => {
      this.$store.commit('set', {socket});
      console.log('soc connected:');
    });
    socket.on("disconnect", () => {
      this.$store.commit('set', {socket: null});
      console.log('soc disconnected');
    });
    socket.on("services", (data) => {
      // console.log('services', data)
      this.$store.commit('set', {services: data});
    });
    socket.on("new-service", (srv) => {
      this.$store.commit('add', srv);
    });
    socket.on("update-service", (srv) => {
      this.$store.commit('update', srv);
    });
    socket.on("error", msg => {
      console.log('server error', msg);
    });
    socket.on("connect_error", (err) => {
      console.log('connect_error', err);
    });
  },
};
</script>
