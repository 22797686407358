import cookies from 'js-cookie'
import axios from 'axios'


export default {
   namespaced: true,
   state: {user: {}},
   actions: {
      ok({commit}, {token, data}) {
         cookies.set(process.env.VUE_APP_JWT_HEADER, token, {expires: 1});
         axios.defaults.headers.common[process.env.VUE_APP_JWT_HEADER] = token;
         commit('set', data);
      },
      logout({ commit }) {
         delete axios.defaults.headers.common[process.env.VUE_APP_JWT_HEADER]
         cookies.remove(process.env.VUE_APP_JWT_HEADER)
         commit('clear');
      }
   },
   mutations: {
      set(state, {avatar,...user}) {
         state.loggingIn = true;
         if(avatar) user.avatar = process.env.img_url + avatar;
         else user.avatar = '~/assets/avatar.png';
         state.user = user;
      },
      loginFailure(state) {
         state.loggingIn = false;
         state.user = {};
      },
      clear(state) {
         state.loggingIn = false;
         state.user = {};
      },
      setToken() {
         
      }
   }
}

